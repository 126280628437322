html,
body {
  height: 100vh;
  background: #f3f7fd;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Raleway';
  font-weight: bold;
  src: url('./fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-weight: normal;
  src: url('./fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoSlab';
  font-weight: bold;
  src: url('./fonts/RobotoSlab-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoSlab';
  font-weight: normal;
  src: url('./fonts/RobotoSlab-Regular.ttf') format('truetype');
}

#main-container {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.SnackbarContainer-root {
  z-index: 90000000 !important;
}
